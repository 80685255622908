<template>
  <q-card style="width: 832px">
    <q-card-section>
      <div class="text-subtitle2 text-bold q-pl-md text-grey-8">Trigger</div>
      <div class="text-h5 text-bold q-pl-md">{{ $t('label.story.trigger_type') }}</div>
    </q-card-section>
    <q-card-section class="q-pt-none">
      <!-- <div class="flex justify-around"> -->
      <div class="flex" style="justify-content: center">
        <div
          class="trigger-item"
          @click="changeTriggerType('default')"
          :class="value == 'default' ? 'default-selected' : ''"
        >
          <div class="trigger-item__title text-default">{{ $t('label.story.default_trigger') }}</div>
          <q-btn unelevated padding="sm" color="default" icon="person" class="trigger-icon" />
          <div class="trigger-item__subtitle">デフォルトの<br />LINE友だち登録時の初回配信</div>
        </div>
        <!-- <div
          class="trigger-item"
          @click="changeTriggerType('qr_code')"
          :class="value == 'qr_code' ? 'qr-code-selected' : ''"
        >
          <div class="trigger-item__title text-qr-code">{{ $t('label.story.qr_code_trigger') }}</div>
          <q-btn unelevated padding="sm" color="qr-code" icon="qr_code" class="trigger-icon" />
          <div class="trigger-item__subtitle">QRコード/URLからの<br />LINE友だち登録時の初回配信</div>
        </div> -->
        <div
          class="trigger-item"
          @click="changeTriggerType('popup_deliver')"
          :class="value == 'popup_deliver' ? 'popup-deliver-selected' : ''"
        >
          <div class="trigger-item__title" style="color: #3982c2">{{ $t('label.story.popup_deliver_trigger') }}</div>
          <q-btn unelevated padding="sm" icon="flag" class="trigger-icon flag-icon flag-icon__popup_deliver" />
          <div class="trigger-item__subtitle">ポップアップからの<br />LINE友だち登録時の初回配信</div>
        </div>
      </div>
      <!-- <div class="flex" style="justify-content: center">
        <div
          class="trigger-item"
          @click="changeTriggerType('audience')"
          :class="value == 'audience' ? 'audience-selected' : ''"
        >
          <div class="trigger-item__title text-pink">{{ $t('label.story.audience_trigger') }}</div>
          <q-btn unelevated padding="sm" color="pink-3" icon="forum" class="trigger-icon" />
          <div class="trigger-item__subtitle">LINE友だちへの<br />セグメント配信</div>
        </div>
        <div class="trigger-item" @click="changeTriggerType('all')" :class="value == 'all' ? 'all-selected' : ''">
          <div class="trigger-item__title text-purple">{{ $t('label.story.all_trigger') }}</div>
          <q-btn unelevated padding="sm" color="purple-3" icon="send" class="trigger-icon" />
          <div class="trigger-item__subtitle">LINE友だちへの<br />一斉配信</div>
        </div>
      </div> -->
      <div class="row no-wrap items-center q-mt-md">
        <q-space />
        <div class="q-gutter-sm">
          <q-btn no-caps outline color="white" text-color="grey" @click="onCancel">{{ $t('back') }}</q-btn>
          <q-btn no-caps color="grey" @click="onNext"> {{ $t('next') }} </q-btn>
        </div>
      </div>
    </q-card-section>
  </q-card>

  <LIFFHowToSetupFormModal
    v-if="modalLiffHowtoSetupVisible"
    :modalVisible="modalLiffHowtoSetupVisible"
    :data="selectingApp"
  />
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { TRIGGER_TYPE } from '@/utils/constants'
import { ACTION_APP } from '@/store/actions'
import LIFFHowToSetupFormModal from '@/components/campaign-traffic-sources/LIFFHowToSetupFormModal.vue'
import { IApp } from '@/utils/types'

@Options({
  components: { LIFFHowToSetupFormModal },
  directives: { maska },
  emits: ['update:modelValue', 'update:stepValue'],
})
export default class ChooseTriggerType extends Vue {
  @Prop({ default: 'popup_deliver' })
  modelValue!: string

  modalLiffHowtoSetupVisible = false
  selectingApp: IApp = {}

  get value() {
    return this.modelValue
  }

  set value(value: string) {
    this.$emit('update:modelValue', value)
  }

  get appId() {
    return this.$route?.params?.app_id
  }

  changeTriggerType(type) {
    this.value = type
  }

  onCancel() {
    this.goto('stories', { app_id: this.appId })
  }

  async onNext() {
    if (this.value === TRIGGER_TYPE.POPUP_DELIVER) {
      this.selectingApp = await this.$store.dispatch(ACTION_APP.LOAD_ITEM, this.appId)
      if (
        !this.selectingApp.liff_id ||
        this.selectingApp.liff_id === '' ||
        (this.selectingApp.is_test_mode && this.selectingApp.liff_id_test === '')
      ) {
        this.modalLiffHowtoSetupVisible = true
        return
      }
    }

    if (this.value === '') {
      this.$q.dialog({
        title: this.$t('error'),
        message: this.$t('messages.please_select_trigger_type'),
        persistent: true,
        html: true,
      })
      return
    }
    this.$emit('update:stepValue')
  }

  goto(name: string, params = {}) {
    this.$router.push({
      name,
      params,
    })
  }
}
</script>

<style scoped lang="scss">
.box {
  width: 261px;
  height: 186px;
}
.trigger-item {
  flex: 1;
  margin: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border: 1px dotted #333333;
  border-radius: 8px;
  padding: 0 16px;
  cursor: pointer;
  max-width: 261px;

  &__title {
    font-size: 16px;
    font-weight: 700;
    line-height: 34.75px;
    text-align: center;
  }

  &__subtitle {
    font-size: 13px;
    font-weight: 400;
    line-height: 23.17px;
    text-align: center;
    color: #8f969e;
  }
}

.trigger-icon {
  border-radius: 10px;
}
:deep(.trigger-icon .q-icon) {
  font-size: 3.5em;
}

.text-default {
  color: #73ce52 !important;
}
.bg-default {
  background: #73ce5299 !important;
}

.text-qr-code {
  color: #c2bd39 !important;
}
.bg-qr-code {
  background: #c2bd39 !important;
}
</style>
