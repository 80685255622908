
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { TRIGGER_TYPE } from '@/utils/constants'
import { ACTION_APP } from '@/store/actions'
import LIFFHowToSetupFormModal from '@/components/campaign-traffic-sources/LIFFHowToSetupFormModal.vue'
import { IApp } from '@/utils/types'

@Options({
  components: { LIFFHowToSetupFormModal },
  directives: { maska },
  emits: ['update:modelValue', 'update:stepValue'],
})
export default class ChooseTriggerType extends Vue {
  @Prop({ default: 'popup_deliver' })
  modelValue!: string

  modalLiffHowtoSetupVisible = false
  selectingApp: IApp = {}

  get value() {
    return this.modelValue
  }

  set value(value: string) {
    this.$emit('update:modelValue', value)
  }

  get appId() {
    return this.$route?.params?.app_id
  }

  changeTriggerType(type) {
    this.value = type
  }

  onCancel() {
    this.goto('stories', { app_id: this.appId })
  }

  async onNext() {
    if (this.value === TRIGGER_TYPE.POPUP_DELIVER) {
      this.selectingApp = await this.$store.dispatch(ACTION_APP.LOAD_ITEM, this.appId)
      if (
        !this.selectingApp.liff_id ||
        this.selectingApp.liff_id === '' ||
        (this.selectingApp.is_test_mode && this.selectingApp.liff_id_test === '')
      ) {
        this.modalLiffHowtoSetupVisible = true
        return
      }
    }

    if (this.value === '') {
      this.$q.dialog({
        title: this.$t('error'),
        message: this.$t('messages.please_select_trigger_type'),
        persistent: true,
        html: true,
      })
      return
    }
    this.$emit('update:stepValue')
  }

  goto(name: string, params = {}) {
    this.$router.push({
      name,
      params,
    })
  }
}
