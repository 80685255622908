import { render } from "./TestModeSelector.vue?vue&type=template&id=2dfb6da4"
import script from "./TestModeSelector.vue?vue&type=script&lang=ts"
export * from "./TestModeSelector.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QSpace from 'quasar/src/components/space/QSpace.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QBtnGroup from 'quasar/src/components/btn-group/QBtnGroup.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QSpace,QBtn,QBtnGroup,QTooltip});
