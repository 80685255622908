
import { mixins, Options } from 'vue-class-component'
import PushCampaignMixin from '@/components/push-campaign/mixins/PushCampaignMixin.vue'
import BaseFormMixin from '@/components/common/mixins/BaseFormMixin.vue'
import ScenariosMaker from '@/components/story/scenarios/ScenariosMaker.vue'
import { maska } from 'maska'
import { Prop, Watch } from 'vue-property-decorator'
import { IInitialCampaign, IPushCampaign, IStory, IValidRequest } from '@/utils/types'
import { ICard } from 'bot-flow-maker/src/types'
import { ACTION_APP } from '@/store/actions'
import cloneDeep from 'lodash/cloneDeep'
import { STARTING_POINT_TYPE, TRIGGER_TYPE } from '@/utils/constants'

@Options({
  components: { ScenariosMaker },
  directives: { maska },
  emits: ['update:modelValue', 'update:onSave', 'update:onNextStep', 'update:openStartSettings'],
  async beforeRouteLeave() {
    if (!this) {
      return
    }
    this.isCancel = false
    return await this.checkDraftValue()
  },
})
export default class Push extends mixins(PushCampaignMixin, BaseFormMixin) {
  @Prop({})
  modelValue!: IStory

  @Prop()
  selectedPushIndex!: number

  @Prop({})
  isTestMode!: boolean

  @Prop()
  isOpenStartSettings!: boolean

  @Prop()
  isStoryFirstPush!: boolean

  @Prop({})
  isCopy!: boolean

  previousCampaign: IPushCampaign | IInitialCampaign = {}
  scenarios: ICard[] = []
  directionType = '0'
  campaignName: string | undefined = ''
  form: IPushCampaign = {
    _id: '',
    title: '',
    app_id: '',
    delivered: 0,
    is_active: false,
    is_test_mode: false,
    audience_size: 0,
    prod_detail: {
      starting_point: STARTING_POINT_TYPE.SCENARIO_START,
      direction_type: 1,
      audience: {
        card_or: [],
        card_not: [],
        audience_or: [],
        audience_not: [],
        unreached_users: [],
      },

      frequency: {
        active: false,
        time_per_user: 1,
      },
      schedule: {
        schedule_type: 'multi_time',
        schedule_by_type: 'by_delay_date',
        delay_day: 0,
        day_of_week: 0,
        day_of_month: 1,
        date: '',
        time: '',
      },
      scenarios: [],
    },
    test_detail: {
      starting_point: STARTING_POINT_TYPE.SCENARIO_START,
      direction_type: 1,
      audience: {
        card_or: [],
        card_not: [],
        audience_or: [],
        audience_not: [],
        unreached_users: [],
      },

      frequency: {
        active: false,
        time_per_user: 1,
      },
      schedule: {
        schedule_type: 'multi_time',
        schedule_by_type: 'by_delay_date',
        delay_day: 0,
        day_of_week: 0,
        day_of_month: 1,
        date: '',
        time: '',
      },
      scenarios: [],
    },
  }

  get story() {
    return this.modelValue
  }

  set story(value: IStory) {
    this.$emit('update:modelValue', value)
  }

  get openStartSettings() {
    return this.isOpenStartSettings
  }

  set openStartSettings(value: boolean) {
    this.$emit('update:openStartSettings', value)
  }

  @Watch('isTestMode', { immediate: true })
  @Watch('selectedPushIndex', { immediate: true })
  async onChangeTestMode() {
    if (this.selectedPushIndex === 0 && this.story.trigger_type === TRIGGER_TYPE.AUDIENCE) {
      if (this.story.push) {
        this.form = cloneDeep(this.story.push)
      }
    } else {
      if (this.story.pushes) {
        this.form = cloneDeep(this.story.pushes[this.selectedPushIndex])
        if (
          this.story.trigger_type === TRIGGER_TYPE.POPUP_DELIVER ||
          this.story.trigger_type === TRIGGER_TYPE.QR_CODE ||
          this.story.trigger_type === TRIGGER_TYPE.DEFAULT
        ) {
          if (this.selectedPushIndex === 0) {
            this.previousCampaign = this.story.initial ?? {}
          } else {
            this.previousCampaign = cloneDeep(this.story.pushes[this.selectedPushIndex - 1])
          }
        }
      }
    }

    if (this.isTestMode) {
      if (this.form.test_detail) {
        this.scenarios = this.form.test_detail.scenarios ?? []
        this.directionType = this.form.test_detail.direction_type?.toString() ?? '0'
      }
    } else {
      if (this.form.prod_detail) {
        this.scenarios = this.form.prod_detail.scenarios ?? []
        this.directionType = this.form.prod_detail.direction_type?.toString() ?? '0'
      }
    }
    this.campaignName = this.form.title
  }

  onChangeDirection(value) {
    this.directionType = value
    if (this.isTestMode) {
      if (this.form.test_detail) {
        this.form.test_detail.direction_type = Number.parseInt(value)
      }
    } else {
      if (this.form.prod_detail) {
        this.form.prod_detail.direction_type = Number.parseInt(value)
      }
    }
  }

  onChangeScenarios(value) {
    this.scenarios = value
    if (this.isTestMode) {
      if (this.form.test_detail) {
        this.form.test_detail.scenarios = value
      }
    } else {
      if (this.form.prod_detail) {
        this.form.prod_detail.scenarios = value
      }
    }

    if (this.story.pushes) {
      this.story.pushes[this.selectedPushIndex] = cloneDeep(this.form)
      if (this.selectedPushIndex === 0 && this.story.trigger_type === TRIGGER_TYPE.AUDIENCE) {
        this.story.push = this.form
      }
    }
  }

  validate(isSave = false) {
    this.onSubmit(isSave)
  }

  async checkSizeCardsOfScenarios() {
    if (this.isNew) {
      return true
    }
    const { dispatch } = this.$store
    const params: IValidRequest = {
      app_id: this.form.app_id ?? '',
      initial_id: '',
      push_id: '',
      card_id: '',
      card_type: '',
      total_messages: 0,
      campaign: this.form,
    }
    const resp = await dispatch(ACTION_APP.CHECK_LIMIT_CARDS, params)
    if (resp.count > 5) {
      const card = resp.card
      this.$refs.scenarioEditor.handleErrorCard(card.uniqueId)
      this.$q.dialog({
        title: this.$t('error'),
        message: this.$t('messages.size_must_be_between_1_and_5', {
          card_title: card.title,
          count: resp.count,
          campaign_type: resp.campaign_type,
          campaign_title: resp.campaign_title,
        }),
        persistent: true,
        html: true,
      })
      return false
    }
    return true
  }

  async onSubmit(isSave = false) {
    // if (!(await this.checkSizeCardsOfScenarios())) {
    //   return
    // }
    if (this.isStoryFirstPush) {
      // this.form.is_active = this.story.is_active
      // this.form.is_test_mode = this.story.is_test_mode
      this.story.push = cloneDeep(this.form)
    }

    if (this.story.pushes) {
      // this.form.is_active = this.story.is_active
      // this.form.is_test_mode = this.story.is_test_mode
      this.story.pushes[this.selectedPushIndex] = cloneDeep(this.form)
    }

    if (isSave) {
      this.$emit('update:onSave')
    } else {
      this.$emit('update:onNextStep')
    }
  }

  onUpdateStartSettings(value) {
    if (this.story.pushes) {
      this.story.pushes[this.selectedPushIndex] = cloneDeep(this.form)
    }
  }

  updateCampaignName() {
    if (this.story.pushes) {
      this.form.title = this.campaignName
      this.story.pushes[this.selectedPushIndex].title = this.campaignName
    }
  }

  created() {
    this.onChangeTestMode()
    this.parentForm = this.form
    this.initForm = cloneDeep(this.parentForm)
    this.campaignName = this.form.title
  }
}
