<template>
  <q-dialog v-model="visible" full-height position="right">
    <q-card style="width: 1000px; max-width: 80vw">
      <q-toolbar>
        <q-toolbar-title> 初期設定 </q-toolbar-title>
        <q-btn flat round dense icon="close" @click="onCancel" />
      </q-toolbar>
      <q-card-section style="padding-top: 0px">
        <div class="q-mb-md">
          <div class="row text-subtitle1 text-bold">設定環境</div>
          <div class="row" style="margin-left: -8px">
            <TestModeSelector v-model="isTestMode" :isHideCopy="true" :isDisable="step !== 1" />
          </div>
          <div class="row text-caption">※初回のみ選択が可能です。</div>
        </div>
        <q-form ref="formRef" greedy>
          <q-stepper
            v-model="step"
            header-nav
            ref="stepper"
            done-color="active"
            active-color="active"
            inactive-color="inactive"
            animated
            :key="componentKey"
          >
            <q-step
              :name="1"
              title="LINE APPの作成"
              icon="settings"
              :done="step > 1"
              :header-nav="step > 1"
              class="step-settings"
            >
              <div>
                <p class="q-pb-sm" style="font-weight: 700">エンドポイントURL</p>
                <q-badge outline align="middle" color="primary">
                  <TagContent :tagValue="getWebhookURL()"></TagContent>
                </q-badge>
              </div>
              <p class="q-pb-sm q-pt-sm">
                LINEデベロッパーコンソールよりLIFFアプリケーションを作成しエンドポイントを設定してください。作成したLIFF
                IDを控えてください。
              </p>
              <b>LIFF APPの作成方法</b>
              <ol>
                <li>LINE Developerで新規チャンネル作成を選択</li>
                <li>チャンネルタイプを"LINE Login"を選択</li>
                <li>任意のチャンネル情報を入力と作成</li>
                <li>作成したチャンネルのLIFFタブに移動し追加を選択</li>
                <li>以下情報を設定後、作成</li>
                <li>
                  LIFFアプリ名: 任意<br />
                  <ul>
                    <li>サイズ: Full</li>
                    <li>エンドポイントURL: 取得したエンドポイントURLを設定</li>
                    <li>
                      Scope: profile, openId,
                      chat_message.writeを選択。(chat_message.writeは"全て表示"をクリックすると表示されます)
                    </li>
                    <li>ボットリンク機能: ON</li>
                    <li>Scan QR: 有効</li>
                  </ul>
                </li>
              </ol>
              <q-stepper-navigation>
                <div class="row no-wrap items-center">
                  <q-space />
                  <div class="q-gutter-sm">
                    <q-btn
                      no-caps
                      class="btn-save"
                      @click="
                        () => {
                          done1 = true
                          step = 2
                        }
                      "
                    >
                      {{ $t('next') }}
                    </q-btn>
                  </div>
                </div>
              </q-stepper-navigation>
            </q-step>

            <q-step :name="2" title="LIFF IDの更新" icon="badge" :header-nav="step > 1" class="step-liff-id">
              <div v-if="isTestMode">
                <label class="text-grey-9 text-bold">LIFF ID（テストモード）</label>
                <q-input
                  lazy-rules
                  :rules="[(val) => (val && val.length > 0) || requiredRule.liff_id]"
                  outlined
                  v-model="form.liff_id_test"
                  dense
                  :placeholder="$t('label.traffic_source.liff_id')"
                >
                </q-input>
              </div>
              <div v-else>
                <label class="text-grey-9 text-bold">LIFF ID</label>
                <q-input
                  lazy-rules
                  :rules="[(val) => (val && val.length > 0) || requiredRule.liff_id]"
                  outlined
                  v-model="form.liff_id"
                  dense
                  :placeholder="$t('label.traffic_source.liff_id')"
                >
                </q-input>
              </div>
              <q-stepper-navigation>
                <div class="row no-wrap items-center">
                  <q-space />
                  <div class="q-gutter-sm">
                    <q-btn flat @click="step = 1" label="戻る" class="btn-save-grey" />
                    <q-btn no-caps class="btn-save" @click="onSubmit()">
                      {{ $t('save') }}
                    </q-btn>
                  </div>
                </div>
              </q-stepper-navigation>
            </q-step>
          </q-stepper>
        </q-form>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script lang="ts">
import { Options, mixins } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import { IApp } from '@/utils/types'
import cloneDeep from 'lodash/cloneDeep'
import { ACTION_APP } from '@/store/actions'
import CLoading from '@/components/common/ui/CLoading.vue'
import BaseFormDialogMixin from '../common/mixins/BaseFormDialogMixin.vue'
import TagContent from '@/components/common/ui/TagContent.vue'
import TestModeSelector from '@/components/common/ui/TestModeSelector.vue'

@Options({
  components: { CLoading, TagContent, TestModeSelector },
  directives: { maska },
  emits: ['update:onSelect'],
})
export default class LIFFHowToSetupFormModal extends mixins(BaseFormDialogMixin) {
  @Prop()
  data!: IApp

  form: IApp = {}
  step = 1
  isTestMode = false
  componentKey = 0

  get requiredRule() {
    const requiredRule = {
      liff_id: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.traffic_source.liff_id'),
      }),
    }
    return requiredRule
  }

  @Watch('isTestMode', { immediate: true })
  isTestModeChange() {
    this.componentKey++
  }

  onSubmit() {
    this.$refs.formRef
      .validate()
      .then(async (success: boolean) => {
        if (!success) {
          return
        }

        await this.doSave()
      })
      .catch((error: unknown) => {
        console.log('error', error)
      })
  }

  async doSave() {
    const { dispatch } = this.$store
    let success = false
    success = await dispatch(ACTION_APP.UPDATE, {
      _id: this.form._id,
      ...this.form,
    })

    if (success) {
      this.$q.notify({
        message: this.$t('messages.saved'),
        color: 'positive',
      })
    }

    this.visible = false
  }

  getWebhookURL() {
    const baseUrl = process.env.VUE_APP_API_ENDPOINT
    let url = `${baseUrl}webhook/liff-app?app_id=${this.form._id}`
    if (this.isTestMode) {
      url = `${baseUrl}webhook/test/liff-app?app_id=${this.form._id}`
    }
    return url
  }

  onCancel() {
    this.visible = false
    this.goto('overview', { app_id: this.form._id })
  }

  goto(name: string, params = {}) {
    this.$router.push({
      name,
      params,
    })
  }

  created() {
    this.form = { ...this.form, ...cloneDeep(this.data) }

    if (!this.form.liff_id || this.form.liff_id === '') {
      this.isTestMode = false
    } else {
      if (this.form.is_test_mode && this.form.liff_id_test === '') {
        this.isTestMode = true
      }
    }
  }
}
</script>
<style lang="scss" >
.text-active {
  color: #3982c2 !important;
  .q-stepper__label {
    width: 60%;
    background-color: #3982c2;
    color: white;
    border-radius: 20px;
    padding-left: 12px;
    padding-bottom: 6px;
    padding-top: 6px;
  }
}
.text-inactive {
  color: #8f969e !important;
  .q-stepper__label {
    width: 60%;
    background-color: #8f969e;
    color: white;
    border-radius: 20px;
    padding-left: 12px;
    padding-bottom: 6px;
    padding-top: 6px;
  }
}

.q-stepper__tab:last-child {
  justify-content: center !important;
}
.q-stepper__dot {
  margin-right: 8px;
  font-size: 23px;
  width: 24px;
  min-width: 36px;
  height: 36px;
  border-radius: 50%;
}
</style>
