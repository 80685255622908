<template>
  <q-dialog v-model="visible">
    <q-card style="width: 1000px; max-width: 90vw">
      <q-card-section class="q-pa-none">
        <q-form ref="formRef" greedy>
          <CLoading :loading="loading" />
          <div class="flex justify-between" style="border-bottom: 1px solid #d0d2e0">
            <div class="text-h5 q-ml-md" style="border-bottom: 1px solid black">送信テスト</div>
            <q-btn flat round dense icon="close" @click="onCancel" />
          </div>
          <div class="row" style="height: 500px">
            <div class="col-4" style="border-right: 1px solid #d0d2e0">
              <q-stepper
                v-model="step"
                vertical
                color="primary"
                flat
                done-color="done-color"
                active-color="active-color"
                inactive-color="inactive-color"
              >
                <q-step
                  :name="1"
                  title="シナリオ開始地点の選択"
                  icon="history_edu"
                  done-icon="history_edu"
                  active-icon="history_edu"
                  :done="step > 1"
                  s
                >
                </q-step>
                <q-step
                  :name="2"
                  title="配信する設定の選択"
                  icon="shuffle"
                  done-icon="shuffle"
                  active-icon="shuffle"
                  :done="step > 2"
                >
                </q-step>
                <q-step
                  :name="3"
                  title="配信先ユーザーの選択"
                  icon="people"
                  done-icon="people"
                  active-icon="people"
                  :done="step > 3"
                >
                </q-step>
                <q-step
                  :name="4"
                  title="配信内容の確認"
                  icon="check_circle_outline"
                  done-icon="check_circle_outline"
                  active-icon="check_circle_outline"
                  :done="step > 4"
                >
                </q-step>
                <q-step
                  :name="5"
                  title="テスト配信完了"
                  icon="send"
                  done-icon="send"
                  active-icon="send"
                  :done="step > 5"
                >
                </q-step>
              </q-stepper>
            </div>
            <div class="col-8">
              <div class="q-pa-sm" style="border-bottom: 1px solid #d0d2e0" v-if="step !== 5">
                <div v-if="step == 1">どのシナリオから開始するか選択してください</div>
                <div v-if="step == 2">どちらの環境の設定内容を送信するか選択してください）</div>
                <div v-if="step == 3">テスト配信を行うユーザー選択してください（複数選択可）</div>
                <div v-if="step == 4">どちらの環境の設定内容を送信するか選択してください</div>
              </div>

              <div class="q-pa-sm" :class="step === 5 ? 'done-center' : ''">
                <div v-if="step == 1" class="items-center">
                  <div
                    class="row"
                    v-if="
                      (story.trigger_type === 'default' ||
                        story.trigger_type === 'qr_code' ||
                        story.trigger_type === 'popup_deliver') &&
                      (value > 2 || story.initial?.title)
                    "
                  >
                    <div
                      class="col dotted-grey-border cursor-pointer initial"
                      :class="value === 3 ? 'initial-active' : ''"
                      style="position: relative"
                      @click="value = 3"
                    >
                      <div class="text-caption cm-blue-text">{{ $t('label.story.scenario') }}</div>
                      <div class="row full-width q-ma-sm" style="gap: 8px">
                        <q-icon name="history_edu" class="flag-icon flag-icon__initial q-ml-sm" size="1.5em" />
                        <div class="text-subtitle1 text-bold cm-blue-text">初回メッセージ</div>
                      </div>
                    </div>
                  </div>

                  <div v-for="(item, index) in story.pushes" :key="index">
                    <div v-if="(story.trigger_type === 'audience' && value >= 2) || value > 3 || story.pushes?.length">
                      <div class="vertical-line" style="height: 8px"></div>

                      <div class="vertical-line" style="height: 8px"></div>
                    </div>
                    <div
                      class="row"
                      v-if="(story.trigger_type === 'audience' && value >= 2) || value > 3 || story.pushes?.length"
                    >
                      <div
                        class="col dotted-grey-border cursor-pointer"
                        :class="value === 4 && pushIndex === index ? 'push-active' : ''"
                        style="position: relative"
                      >
                        <div class="text-caption cm-black-text">{{ $t('label.story.scenario') }}</div>
                        <div class="row full-width q-ma-sm" style="gap: 8px" @click="selectPush(index)">
                          <q-icon name="history_edu" class="flag-icon flag-icon__push q-ml-sm" size="1.5em" />
                          <div class="text-subtitle1 text-bold">
                            {{ item.title ? item.title : 'メッセージ：' + (index + 2) }}
                          </div>
                          <q-tooltip>{{ item.title ? item.title : 'メッセージ：' + (index + 2) }}</q-tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="step == 2">
                  <div>
                    <q-radio size="sm" round outline v-model="isTestMode" :val="true" label="テスト" />
                  </div>
                  <div>
                    <q-radio size="sm" round outline v-model="isTestMode" :val="false" label="本番" />
                  </div>
                </div>
                <div v-if="step == 3">
                  <q-table
                    v-if="lineUsers"
                    :rows="lineUsers"
                    :columns="columns"
                    v-model:pagination="pagination"
                    :rows-per-page-options="rowPerPageOptions"
                    row-key="user_id"
                    ref="tableRef"
                    @request="onRequest"
                    selection="multiple"
                    v-model:selected="selectedUsers"
                    hide-header
                    virtual-scroll
                    :style="lineUsers.length > 0 ? 'height: 449px' : ''"
                    hide-selected-banner
                    :no-data-label="$t('messages.no_data_available')"
                    bordered
                  >
                    <template v-slot:body="props">
                      <q-tr :props="props" class="cursor-pointer">
                        <q-td key="display_name" :props="props" style="width: 25px">
                          <q-checkbox dense v-model="props.selected">
                            <UserAvatar :size="24" :user="getUserInfo(props.row.display_name, props.row.picture_url)" />
                            <span class="q-ml-sm">{{ props.row.display_name }}</span>
                          </q-checkbox>
                        </q-td>
                      </q-tr>
                    </template>
                  </q-table>
                </div>
                <div v-if="step == 4">
                  <div>シナリオ開始地点</div>
                  <div class="q-ma-md">
                    <div v-if="value === 3">初回メッセージ</div>
                    <div v-else>{{ pushName }}</div>
                  </div>
                  <div>配信先ユーザー</div>
                  <div class="q-ma-md">
                    <div v-for="(item, index) in selectedUsers" :key="index" class="q-pb-sm">
                      <UserAvatar :size="24" :user="getUserInfo(item.display_name, item.picture_url)" />
                      <span class="q-ml-sm">{{ item.display_name }}</span>
                    </div>
                  </div>
                  <div>配信設定</div>
                  <div class="q-ma-md">
                    <div v-if="isTestMode">テスト</div>
                    <div v-else>本番</div>
                  </div>
                </div>

                <div v-if="step === 5" class="items-center text-center">
                  <q-icon name="done" class="custom-icon-bg" size="50px" /><br />

                  テスト配信が完了しました
                </div>
              </div>
              <q-space class="q-my-md" v-if="step != 3" />
            </div>
          </div>
        </q-form>
      </q-card-section>

      <div class="flex justify-end" style="border-top: 1px solid #d0d2e0">
        <q-space />
        <div class="q-gutter-sm q-pa-sm">
          <q-btn flat @click="onPrev" label="戻る" class="btn-save-grey" v-if="step > 1 && step < 5" />
          <q-btn @click="onNext" label="次へ" class="btn-save" v-if="step < 4" />
          <q-btn @click="onSend" label="配信" class="btn-save" v-if="step == 4" />

          <q-btn no-caps flat class="btn-save-grey" @click="onCancel" v-if="step == 5">{{ $t('close') }}</q-btn>
        </div>
      </div>
    </q-card>
  </q-dialog>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import { IAppUser, ISendingTestRequest, IStory, IUser } from '@/utils/types'
import { constant } from '@/utils/constants'
import { ACTION_APP_USER, ACTION_STORY } from '@/store/actions'
import UserAvatar from '@/components/user/UserAvatar.vue'
import CLoading from '@/components/common/ui/CLoading.vue'

@Options({
  components: { UserAvatar, CLoading },
  directives: { maska },
  emits: ['update:closeModal', 'update:onSelect'],
})
export default class SendingTestModal extends Vue {
  @Prop()
  modalVisible!: boolean

  @Prop()
  story!: IStory

  loading = false
  step = 1
  value = 0
  pushIndex = 0
  isTestMode = true
  selectedUsers: IAppUser[] = []

  lineUsers = []
  pagination = {
    sortBy: '',
    descending: false,
    page: 1,
    rowsPerPage: constant.ROW_PER_PAGE,
    rowsNumber: 10,
  }

  columns = [{ name: 'display_name', label: 'display_name', field: 'display_name', align: 'left' }]

  get pushName() {
    if (this.story.pushes) {
      return this.story.pushes[this.pushIndex].title
    }
    return ''
  }

  get visible() {
    return this.modalVisible
  }

  set visible(value) {
    this.$emit('update:closeModal', value)
  }

  get rowPerPageOptions() {
    return constant.ROW_PER_PAGE_OPTIONS
  }

  @Watch('isTestMode', { immediate: true })
  async onChangeIsTestMode() {
    this.selectedUsers = []
  }

  selectPush(index: number) {
    this.value = 4
    this.pushIndex = index
  }

  onCancel() {
    this.visible = false
  }

  getUserInfo(display_name, picture_url) {
    const u: IUser = {
      photo_url: picture_url,
      display_name: display_name,
    }
    return u
  }

  async onRequest(props) {
    this.loading = true
    const { page, rowsPerPage } = props.pagination
    const returnedData = await this.fetchFromServer(page, rowsPerPage)
    if (returnedData) {
      // eslint-disable-next-line
      const items: [] = returnedData['items']
      this.lineUsers.splice(0, this.lineUsers.length, ...items)
      // eslint-disable-next-line
      this.pagination.rowsNumber = returnedData['total_items']
    }
    this.pagination.page = page
    this.pagination.rowsPerPage = rowsPerPage
    this.loading = false
  }

  async fetchFromServer(page, rowsPerPage) {
    const items = await this.$store.dispatch(ACTION_APP_USER.LOAD_ITEMS, {
      app_id: this.story.app_id,
      is_test_mode: this.isTestMode,
      filter: {
        page: page,
        per_page: rowsPerPage,
      },
    })

    return items
  }

  onPrev() {
    this.step--
    this.lineUsers = []
    if (this.step === 3) {
      this.$nextTick(() => {
        // get initial data from server (1st page)
        this.$refs.tableRef.requestServerInteraction()
      })
    }
  }

  onNext() {
    if (this.step === 1) {
      if (this.value === 0) {
        this.$q.dialog({
          title: this.$t('error'),
          message: 'Please choose scenario!',
          persistent: true,
          html: true,
        })
        return
      }
    } else if (this.step === 3) {
      if (this.selectedUsers.length === 0) {
        this.$q.dialog({
          title: this.$t('error'),
          message: 'Please choose users!',
          persistent: true,
          html: true,
        })
        return
      }
    }
    this.lineUsers = []
    this.step++
    if (this.step === 3) {
      this.$nextTick(() => {
        // get initial data from server (1st page)
        this.$refs.tableRef.requestServerInteraction()
      })
    }
  }

  async onSend() {
    this.loading = true
    let initial_id = ''
    let push_id = ''
    const user_ids: string[] = []
    if (this.value === 3) {
      initial_id = this.story.initial?._id ?? ''
    } else {
      if (this.story.pushes) {
        push_id = this.story.pushes[this.pushIndex]._id ?? ''
      }
    }

    this.selectedUsers.forEach((element) => {
      user_ids.push(element.user_id ?? '')
    })

    const payload: ISendingTestRequest = {
      app_id: this.story.app_id,
      story_id: this.story._id,
      initial_id: initial_id,
      push_id: push_id,
      is_test_mode: this.isTestMode,
      user_ids: user_ids,
    }

    const { dispatch } = this.$store
    const result = await dispatch(ACTION_STORY.SENDING_TEST, payload)
    console.log(result, 'onSend')

    this.loading = false
    this.step++
  }
}
</script>

<style lang="scss" scoped>
.text-done-color {
  color: #8f969e !important;
}

.text-active-color {
  color: #3982c2 !important;
}

.text-inactive-color {
  color: #8f969e !important;
}

.dotted-grey-border {
  margin-right: 4px;
}

.text-caption {
  position: absolute;
  top: 0;
  left: 38px;
  font-size: 8px;
}
.text-subtitle1 {
  font-size: 12px;
  font-weight: 700;
}
.vertical-line {
  border-right: 1px solid #d9d9d9;
  height: 36px;
  width: 50%;
}
.horizontal-dotted-line {
  border-bottom: 1px dotted grey;
  height: 50%;
}

.custom-icon-bg {
  background-color: #429488;
  border-radius: 50%; /* To make the background circular */
  padding: 20px; /* Adjust padding to fit the icon size */
  color: white;
}

.done-center {
  height: 100%;
  align-content: center;
}
</style>
