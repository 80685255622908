<template>
  <q-table
    flat
    dense
    v-if="items"
    :rows="items"
    row-key="date"
    separator="cell"
    :no-data-label="$t('messages.no_data_available')"
    hide-bottom
    class="transparent-table"
    :rows-per-page-options="[0]"
  >
    <template v-slot:header>
      <q-tr>
        <q-th rowspan="2" class="text-center" style="width: 64px"></q-th>
        <q-th rowspan="2" key="total_display" class="text-right">
          <span>{{ $t('label.total_display') }}</span>
        </q-th>
        <q-th rowspan="2" key="click_rate" class="text-right">
          <span>{{ $t('label.click_rate') }}</span>
        </q-th>
        <q-th rowspan="2" key="total_click" class="text-right">
          <span>{{ $t('label.total_click') }}</span>
        </q-th>
      </q-tr>
    </template>
    <template v-slot:body="props">
      <q-tr :props="props" @click="onRowClick(props.row)" :class="props.row._id == value._id ? 'row-selected' : ''">
        <q-td>
          <div
            :style="{
              backgroundImage: `url(${props.row.images[0].pc.img_thumb})`,
              backgroundSize: 'cover',
            }"
            style="width: 64px; height: 64px"
            class="square radius-4 img-content"
          >
            <div
              v-if="statusMode(props.row)"
              style="align-content: center; text-align: center; background-color: #333333cc; color: white; opacity: 80%"
              class="fit square radius-4"
            >
              {{ $t('label.story.in_use') }}
            </div>
            <q-tooltip> {{ props.row.popup_name }}</q-tooltip>
          </div>
        </q-td>
        <q-td key="total_display" class="text-right"> {{ numberWithCommas(props.row.total_display) }}</q-td>
        <q-td key="click_rate" class="text-right"> {{ roundNumber(props.row.click_rate) }}%</q-td>
        <q-td key="total_click" class="text-right"> {{ numberWithCommas(props.row.total_click) }}</q-td>
      </q-tr>
    </template>
  </q-table>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import { IPopup } from '@/utils/types'
import { Formater } from '@/utils/formater'
import { ACTION_POPUP } from '@/store/actions'

@Options({
  components: {},
  directives: { maska },
  emits: ['update:modelValue'],
})
export default class PopupListView extends Vue {
  @Prop()
  modelValue!: IPopup

  @Prop()
  popupList!: IPopup[]

  @Prop({})
  isTestMode!: boolean

  items: IPopup[] = []

  get value() {
    return this.modelValue
  }

  set value(value: IPopup) {
    this.$emit('update:modelValue', value)
  }

  statusMode(item: IPopup) {
    if (this.isTestMode) {
      return item.is_test_mode ?? false
    } else {
      return item.is_active ?? false
    }
  }

  @Watch('isTestMode', { immediate: true })
  async loadData() {
    console.log('isTestMode')

    if (this.isTestMode) {
      const popups: IPopup[] = await this.$store.dispatch(ACTION_POPUP.LOAD_ITEMS, {
        app_id: this.value.app_id,
        campaign_id: this.value.campaign_id,
        is_test_mode: this.isTestMode,
      })

      this.items = popups
    } else {
      this.items = this.popupList
    }
  }

  onRowClick(item: IPopup) {
    this.value = item
  }

  numberWithCommas(num) {
    return Formater.numberFormat(num)
  }

  roundNumber(num) {
    return Formater.round(num)
  }
}
</script>

<style scoped lang="scss">
.transparent-table {
  background-color: transparent !important;
}
.q-table--dense .q-table th:first-child,
.q-table--dense .q-table td:first-child {
  padding-left: 8px;
}
.row-selected .fit.square.radius-4 {
  border-radius: 0;
}
</style>

function Watch(arg0: string, arg1: { immediate: boolean }) {
  throw new Error('Function not implemented.')
}
