
import { Vue, Options } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import { IPopup } from '@/utils/types'
import { Formater } from '@/utils/formater'
import { ACTION_POPUP } from '@/store/actions'

@Options({
  components: {},
  directives: { maska },
  emits: ['update:modelValue'],
})
export default class PopupListView extends Vue {
  @Prop()
  modelValue!: IPopup

  @Prop()
  popupList!: IPopup[]

  @Prop({})
  isTestMode!: boolean

  items: IPopup[] = []

  get value() {
    return this.modelValue
  }

  set value(value: IPopup) {
    this.$emit('update:modelValue', value)
  }

  statusMode(item: IPopup) {
    if (this.isTestMode) {
      return item.is_test_mode ?? false
    } else {
      return item.is_active ?? false
    }
  }

  @Watch('isTestMode', { immediate: true })
  async loadData() {
    console.log('isTestMode')

    if (this.isTestMode) {
      const popups: IPopup[] = await this.$store.dispatch(ACTION_POPUP.LOAD_ITEMS, {
        app_id: this.value.app_id,
        campaign_id: this.value.campaign_id,
        is_test_mode: this.isTestMode,
      })

      this.items = popups
    } else {
      this.items = this.popupList
    }
  }

  onRowClick(item: IPopup) {
    this.value = item
  }

  numberWithCommas(num) {
    return Formater.numberFormat(num)
  }

  roundNumber(num) {
    return Formater.round(num)
  }
}
