import { render } from "./PopupListView.vue?vue&type=template&id=5dc32c33&scoped=true"
import script from "./PopupListView.vue?vue&type=script&lang=ts"
export * from "./PopupListView.vue?vue&type=script&lang=ts"

import "./PopupListView.vue?vue&type=style&index=0&id=5dc32c33&scoped=true&lang=scss"
script.render = render
script.__scopeId = "data-v-5dc32c33"

export default script
import QTable from 'quasar/src/components/table/QTable.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTh from 'quasar/src/components/table/QTh.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QTable,QTr,QTh,QTd,QTooltip});
